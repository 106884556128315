import React, { useState } from "react";
import Card from "../../components/card/Card";
import "./Contact.scss";
import { FaPhoneAlt, FaEnvelope} from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { toast } from "react-toastify";
import axios from "axios";
import { BACKEND_URL } from "../../services/authService";
import logo from "../../assets/AR logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';


const Contact = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const data = {
    subject,
    message,
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BACKEND_URL}/api/contactus`, data);
      setSubject("");
      setMessage("");
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const navigate = useNavigate();
  const location = useLocation();

  const handleHomeClick = () => {
    const previousPage = location.state?.from || '/home';
    navigate(previousPage);
  };

  return (
    <div className="contact">
      <nav className="navbar">
        <div className="container --flex-between">
          <div className="navbar-brand">
            <img src={logo} alt="Company Logo" />
            <span>AR Experts LTD</span>
          </div>
          <h1 className="navbar-heading">Contact Us</h1>
          <div className="navbar-links">
          <ul>
            <li>
              <button className="btn-primary-home" onClick={handleHomeClick}>
                <span className="icon"><FontAwesomeIcon icon={faHome} /></span>
                <span className="text">Home</span>
              </button>
            </li>
          </ul>
        </div>
        </div>
      </nav>
      <h3 className="--mt">.</h3>
      <div className="section">
        <form onSubmit={sendEmail}>
          <Card cardClass="card">
            <label>Subject</label>
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              required
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <label>Message</label>
            <textarea
              cols="30"
              rows="10"
              name="message"
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <button className="--btn --btn-primary">Send Message</button>
          </Card>
        </form>

        <div className="details">
          <Card cardClass={"card2"}>
            <h3>Our Contact Information</h3>
            <p>Fill the form or contact us via other channels listed below</p>

            <div className="icons">
              <span>
                <FaPhoneAlt />
                <p>07767934707</p>
              </span>
              <span>
                <FaEnvelope /><p>admin@arexperts.co.uk</p>
              </span>
              <span>
                <GoLocation />
                <p>Salford, M50 1RF</p>
              </span>
              
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Contact;