import React, { useEffect, useRef, useState, useCallback } from "react";
import mqtt from "mqtt";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./ClientUserDevices.scss";
import logo from "../../assets/AR logo.png";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_URL, logoutUser } from "../../services/authService";
import { SET_LOGIN } from "../../redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import deliveryVanIconUrl from "../../assets/delivery_van_icon.png";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

const REACT_APP_FLESPI_TOKEN = process.env.REACT_APP_FLESPI_TOKEN;
const logicTooltips = {
  logic1: "In Logic 1, you will only be able to view the vehicle activity logs",
  logic2: "In Logic 2, you will be able to view the vehicle activity logs and you have access to Lock/Unlock doors remotely along with the Geofence Logs",
  logic3: "In Logic 3, you will be able to view the vehicle activity logs and you have access to Lock/Unlock doors as well as Block/Unblock Engine remotely along with the Geofence Logs",
  logic4: "In Logic 4, you will be able to view the vehicle activity logs and you have access to Lock/Unblock doors, Block/Unblock Engine and CutOff/Enable accelerator remotely along with the Geofence Logs and Vehicle Efficiency(i.e. Fuel Level, Braking Score, Speeding Score and Idling Score)"
};

const ClientUserDevices = () => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markersRef = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [deviceData, setDeviceData] = useState([]);
  const [vehicleData, setVehicleData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [assignedLogics, setAssignedLogics] = useState([]);
  
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/users/${user._id}`);
        const userData = response.data;
        console.log("Fetched Assigned Logics:", userData.logics); 
        setAssignedLogics(userData.logics || []); 
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
  
    fetchUserDetails();
  }, [user]);
  
  
  
  

  console.log("Assigned Logics:", assignedLogics);
  // Initialize map
  useEffect(() => {
    if (mapRef.current && !mapInstance.current) {
      mapInstance.current = L.map(mapRef.current).setView([53.480759, -2.242631], 9);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(mapInstance.current);
    }
  }, []);
  const fetchAssignedDevices = useCallback(async () => {
    if (!user || !user._id) {
      console.error("User ID is not defined");
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${user._id}/devices`);
      if (Array.isArray(response.data)) {
        setDeviceData(response.data);
      } else {
        console.error("Assigned devices response is not an array:", response.data);
        setDeviceData([]);
      }
    } catch (error) {
      console.error("Error fetching assigned devices:", error);
      setDeviceData([]);
    }
  }, [user]);
  // MQTT subscription and data handling
  useEffect(() => {
    if (!user?.assignedDevices?.length) return;
  
    const client = mqtt.connect("wss://mqtt.flespi.io:443", {
      username: REACT_APP_FLESPI_TOKEN,
      clientId: `client_user_${Math.random().toString(16).slice(2)}`,
      clean: true,
    });
  
    client.on("connect", () => {
      console.log("Connected to MQTT broker");
  
      // Ensure we loop through each individual device string
      user.assignedDevices.flat().forEach((device) => {
        if (typeof device === "string" && device.includes("/")) {
          const deviceId = device.split("/")[1];
          client.subscribe(`flespi/state/gw/devices/${deviceId}/telemetry/+`);
          client.subscribe(`flespi/state/gw/devices/${deviceId}/configuration`);
        } else {
          console.warn("Invalid device format. Skipping device:", device);
        }
      });
    });
  
    client.on("message", (topic, message) => {
      const payload = JSON.parse(message.toString());
      const topicParts = topic.split("/");
      const deviceId = topicParts[4];
      const param = topicParts[6];
  
      setVehicleData((prevData) => {
        const updatedData = { ...prevData };
  
        if (!updatedData[deviceId]) {
          updatedData[deviceId] = { deviceId, telemetry: {} };
        }
  
        updatedData[deviceId].telemetry[param] = payload;
  
        return updatedData;
      });
    });
  
    client.on("error", (error) => {
      console.error("MQTT error:", error);
    });
  
    client.on("close", () => {
      console.log("Disconnected from MQTT broker");
    });
  
    return () => {
      client.end();
    };
  }, [user]);
  

  // Update map markers
  useEffect(() => {
    if (mapInstance.current) {
      markersRef.current.forEach((marker) => mapInstance.current.removeLayer(marker));
      markersRef.current = [];

      Object.values(vehicleData).forEach(({ telemetry }) => {
        const { position } = telemetry || {};
        const { latitude, longitude } = position || {};

        if (latitude && longitude) {
          const marker = L.marker([latitude, longitude], {
            icon: L.icon({
              iconUrl: deliveryVanIconUrl,
              iconSize: [30, 40],
              iconAnchor: [15, 40],
              popupAnchor: [0, -40],
            }),
          }).addTo(mapInstance.current);

          markersRef.current.push(marker);
        }
      });
    }
  }, [vehicleData]);

  // Handle search
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const flyToDevice = (longitude, latitude) => {
    if (longitude && latitude) {
      mapInstance.current.flyTo([latitude, longitude], 14);
    } else {
      console.error("Invalid coordinates for device:", { longitude, latitude });
    }
  };

  // Filter and display devices
  const filteredDevices = Object.entries(vehicleData)
    .filter(([deviceId, data]) => {
      const deviceName = data.telemetry["device.name"]?.toLowerCase() || "";
      return deviceName.includes(searchQuery);
    })
    .map(([deviceId, data]) => {
      const telemetry = data.telemetry || {};
      const position = telemetry.position || {};
      const { latitude, longitude } = position;
      const name = telemetry["device.name"] || `Device ${deviceId}`;
      const imei = telemetry.ident || "N/A";
      return { deviceId, name, imei, latitude, longitude };
    });

  const logout = async () => {
    await logoutUser();
    dispatch(SET_LOGIN(false));
    navigate("/");
  };

  const handleLogicSelection = (deviceId, selectedLogic) => {
    if (!assignedLogics.includes(selectedLogic)) {
      console.warn(`Logic ${selectedLogic} is not assigned to this device.`);
      return;
    }

    let dashboardRoute = "";
    switch (selectedLogic) {
      case "logic1":
        navigate(`/logiconedashboard/${deviceId}`, { state: { from: '/client-user-devices' } });
        break;
      case "logic2":
        navigate(`/logictwodashboard/${deviceId}`, { state: { from: '/client-user-devices' } });
        break;
      case "logic3":
        navigate(`/logicthreedashboard/${deviceId}`, { state: { from: '/client-user-devices' } });
        break;
      case "logic4":
        navigate(`/dashboard/${deviceId}`, { state: { from: '/client-user-devices' } });
        break;
      default:
        console.error("Unknown logic selected");
        return;
    }

    console.log(`Navigating to: ${dashboardRoute}`);
  };

  useEffect(() => {
    console.log("Assigned Logics:", assignedLogics);
  }, [assignedLogics]);
  const isLogicAssigned = (logic) => Array.isArray(assignedLogics) && assignedLogics.includes(logic);

  return (
    <div className="userHome">
          <nav className="navbar">
        <div className="container --flex-between">
          <div className="navbar-brand">
            <img src={logo} alt="Company Logo" />
            <span>AR Experts LTD</span>
          </div>
          <h1 className="navbar-heading">User Dashboard</h1>
          <ul className="navbar-links">
            <li><button className="btn-primary signout" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} />Logout</button></li>
          </ul>
        </div>
      </nav>
      <main className="userContent">
        <div className="user-store-listt">
        <div className="user-heading">
            <h2>List of Devices</h2>
        </div>
        <div className='search-container'>
        <div className='search-box'>
            <input
              type="text"
              placeholder="Search devices by name"
              value={searchQuery}
              onChange={handleSearchChange}
              className="device-search-input"
            />
            <i className="fas fa-search search-icon"></i>
        </div>
          </div>
          <ul className="user-listt">
            {filteredDevices.map(({ deviceId, name, imei, latitude, longitude, assignedLogics }) => (
              <li key={deviceId} className="device-item">
                <div className="user-shop-items">
                <Link to="#" className="link-button" onClick={() => flyToDevice(longitude, latitude)}>
            {name}
          </Link> 
                  <p><strong>IMEI:</strong> {imei}</p>
                  <p>
                    <strong>Location:</strong>{" "}
                    {latitude && longitude
                      ? `${latitude}, ${longitude}`
                      : "Fetching..."}
                  </p>
                  {/* <p><strong>{`${assignedLogics.join(', ')} has been assigned`}</strong></p> */}
                  <div className="user-logic-selections">
                    <select
                      className="selectt"
                      onChange={(e) => handleLogicSelection(imei, e.target.value)}
                    >
                      <option value="">Select a logic</option>
                      {["logic1", "logic2", "logic3", "logic4"].map((logic) => (
                        <option
                          key={logic}
                          value={logic}
                          title={!isLogicAssigned(logic) ? logicTooltips[logic] : ""}
                          disabled={!isLogicAssigned(logic)}
                        >
                          {logic} {isLogicAssigned(logic) ? "(Assigned)" : ""}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="map-containerr">
        <div id="map" ref={mapRef} className="map"></div>
        </div>
      </main>
    </div>
  );
};

export default ClientUserDevices;
