import React, { useState, useEffect } from 'react';
import axios from 'axios';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


const EditUser = ({ userId, onClose, onUserUpdated }) => {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    assignedDevices: [],
    logics: []
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/users/${userId}`);
        const [firstName, lastName] = response.data.name.split(' ');
        setUserData({
          firstName,
          lastName,
          email: response.data.email,
          assignedDevices: response.data.assignedDevices,
          logics: response.data.logics
        });
      } catch (error) {
        console.error('Failed to fetch user data', error);
      }
    };
    fetchUser();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleDevicesChange = (e) => {
    const { value, checked } = e.target;
    setUserData((prevUserData) => {
      if (checked) {
        return { ...prevUserData, assignedDevices: [...prevUserData.assignedDevices, value] };
      } else {
        return { ...prevUserData, assignedDevices: prevUserData.assignedDevices.filter((id) => id !== value) };
      }
    });
  };

  const handleLogicsChange = (e) => {
    const { value, checked } = e.target;
    setUserData((prevUserData) => {
      if (checked) {
        return { ...prevUserData, logics: [...prevUserData.logics, value] };
      } else {
        return { ...prevUserData, logics: prevUserData.logics.filter((logic) => logic !== value) };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`${BACKEND_URL}/api/users/${userId}`, userData);
      onUserUpdated(response.data);
      onClose();
    } catch (error) {
      console.error('Failed to update user', error);
    }
  };

  return (
    <div className="edit-user-form">
      <h2>Edit User</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="firstName" value={userData.firstName} onChange={handleChange} placeholder="First Name" required />
        <input type="text" name="lastName" value={userData.lastName} onChange={handleChange} placeholder="Last Name" required />
        <input type="email" name="email" value={userData.email} onChange={handleChange} placeholder="Email" required />
        <div>
          <h3>Assigned Devices</h3>
          {/* Render checkboxes for devices */}
          {userData.assignedDevices.map((device) => (
            <label key={device}>
              <input type="checkbox" value={device} checked={userData.assignedDevices.includes(device)} onChange={handleDevicesChange} />
              {device}
            </label>
          ))}
        </div>
        <div>
          <h3>Logics</h3>
          {/* Render checkboxes for logics */}
          <label>
            <input type="checkbox" value="logic1" checked={userData.logics.includes('logic1')} onChange={handleLogicsChange} />
            Logic 1
          </label>
          <label>
            <input type="checkbox" value="logic2" checked={userData.logics.includes('logic2')} onChange={handleLogicsChange} />
            Logic 2
          </label>
          <label>
            <input type="checkbox" value="logic3" checked={userData.logics.includes('logic3')} onChange={handleLogicsChange} />
            Logic 3
          </label>
          <label>
            <input type="checkbox" value="logic4" checked={userData.logics.includes('logic4')} onChange={handleLogicsChange} />
            Logic 4
          </label>
        </div>
        <button type="submit">Save Changes</button>
        <button type="button" onClick={onClose}>Cancel</button>
      </form>
    </div>
  );
};

export default EditUser;
