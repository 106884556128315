import React, { useEffect, useRef, useState } from "react";
import mqtt from "mqtt";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./AssignedDevices.scss";
import logo from "../../assets/AR logo.png";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_URL, logoutUser } from "../../services/authService";
import { SET_LOGIN } from "../../redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import deliveryVanIconUrl from "../../assets/delivery_van_icon.png";
import { faBars, faHistory, faMapMarkerAlt, faEnvelope, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import AddDeviceForm from "../../components/deviceList/AddDevice";
import CreateClientUserForm from "../../components/deviceList/CreateClientUser";
import ClientUserList from "../../components/users/ClientUserList";
import axios from 'axios';

const REACT_APP_FLESPI_TOKEN = process.env.REACT_APP_FLESPI_TOKEN;

const logicTooltips = {
  logic1: "In Logic 1, you will only be able to view the vehicle activity logs",
  logic2: "In Logic 2, you will be able to view the vehicle activity logs and you have access to Lock/Unlock doors remotely along with the Geofence Logs",
  logic3: "In Logic 3, you will be able to view the vehicle activity logs and you have access to Lock/Unlock doors as well as Block/Unblock Engine remotely along with the Geofence Logs",
  logic4: "In Logic 4, you will be able to view the vehicle activity logs and you have access to Lock/Unblock doors, Block/Unblock Engine and CutOff/Enable accelerator remotely along with the Geofence Logs and Vehicle Efficiency (i.e. Fuel Level, Braking Score, Speeding Score, and Idling Score)",
};

const AssignedDevices = () => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markersRef = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState({});
  const [assignedDevices, setAssignedDevices] = useState([]);
  const user = useSelector(selectUser);
  const [assignedLogics, setAssignedLogics] = useState([]);
  const [showCreateUserForm, setShowCreateUserForm] = useState(false);
  const [showAddDeviceForm, setShowAddDeviceForm] = useState(false);
  const [showUserList, setShowUserList] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState({});
  const [searchKeyword, setSearchKeyword] = useState('');

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
  };


  // Initialize the map
  useEffect(() => {
    if (mapRef.current && !mapInstance.current) {
      mapInstance.current = L.map(mapRef.current).setView([53.480759, -2.242631], 9);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(mapInstance.current);
    }
  }, []);

  // Fetch assigned devices and logics for the user
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/${user._id}`);
        if (response.ok) {
          const userData = await response.json();
          setAssignedDevices(userData.assignedDevices.flat()); // Flatten nested arrays
          setAssignedLogics(userData.logics || []); // Fetch assigned logics
        } else {
          console.error("Failed to fetch user details");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [user]);

  // Fetch assigned devices for the user
  useEffect(() => {
    const fetchAssignedDevices = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/users/${user._id}/devices`
        );
        if (response.ok) {
          const devices = await response.json();
          setAssignedDevices(devices.flat()); // Flatten nested arrays
        } else {
          console.error("Failed to fetch assigned devices");
        }
      } catch (error) {
        console.error("Error fetching assigned devices:", error);
      }
    };

    fetchAssignedDevices();
  }, [user]);

useEffect(() => {
  if (assignedDevices.length === 0) return;

  const client = mqtt.connect("wss://mqtt.flespi.io:443", {
    username: REACT_APP_FLESPI_TOKEN,
    clientId: `user_${Math.random().toString(16).substr(2, 8)}`,
    clean: true,
  });

  client.on("connect", () => {
    console.log("Connected to MQTT broker");

    assignedDevices.forEach((device) => {
      // Check if device is an object or string
      const deviceId = typeof device === "object" ? device.id : device;

      if (typeof deviceId === "string") {
        const idParts = deviceId.split("/");
        const id = idParts.length > 1 ? idParts[1] : deviceId; // Handle malformed data gracefully
        client.subscribe(`flespi/state/gw/devices/${id}/telemetry/+`);
        client.subscribe(`flespi/state/gw/devices/${id}/configuration`); 
      } else {
        console.error("Invalid deviceId:", device);
      }
    });
  });

  client.on("message", (topic, message) => {
    const payload = JSON.parse(message.toString());
    const topicParts = topic.split("/");
    const deviceId = topicParts[4];
    const param = topicParts[6];

    setVehicleData((prevData) => {
      const updatedData = { ...prevData };

      if (!updatedData[deviceId]) {
        updatedData[deviceId] = { deviceId, telemetry: {} }; 
      }

      updatedData[deviceId].telemetry[param] = payload;

      return updatedData;
    });
  });

  client.on("error", (err) => {
    console.error("MQTT error:", err);
  });

  client.on("close", () => {
    console.log("Disconnected from MQTT broker");
  });

  return () => {
    client.end();
  };
}, [assignedDevices]);

  

  // Update markers on the map
  useEffect(() => {
    if (mapInstance.current) {
      markersRef.current.forEach((marker) => mapInstance.current.removeLayer(marker));
      markersRef.current = [];

      Object.values(vehicleData).forEach(({ telemetry, configuration }) => {
        const { position } = telemetry || {};
        const { latitude, longitude } = position || {};

        if (latitude && longitude) {
          const marker = L.marker([latitude, longitude], {
            icon: L.icon({
              iconUrl: deliveryVanIconUrl,
              iconSize: [30, 40],
              iconAnchor: [15, 40],
              popupAnchor: [0, -40],
            }),
          })
            .addTo(mapInstance.current)
            .bindPopup(
              `<strong>${configuration?.name || "Unknown Device"}</strong><br>IMEI: ${
                configuration?.ident || "N/A"
              }`
            );

          markersRef.current.push(marker);
        }
      });
    }
  }, [vehicleData]);

  const logout = async () => {
    await logoutUser();
    await dispatch(SET_LOGIN(false));
    navigate("/");
  };
  

  const handleCreateUserSubmit = async (formData) => {
    try {
      await axios.post(`${BACKEND_URL}/api/users/create-client-user`, formData);
      alert("User created successfully!");
      setShowCreateUserForm(false);
    } catch (error) {
      console.error("Error creating user:", error);
      alert("Failed to create user.");
    }
  };

  const handleAddDeviceSubmit = async (formData) => {
    try {
      await axios.post(`${BACKEND_URL}/api/devices`, formData);
      alert("Device added successfully!");
      setShowAddDeviceForm(false);
    } catch (error) {
      console.error("Error adding device:", error);
      alert("Failed to add device.");
    }
  };

  const filteredDevices = Object.entries(vehicleData || {}).filter(([deviceId, data]) => {
    const deviceName = data.telemetry?.["device.name"]?.toLowerCase() || ""; 
    return deviceName.includes(searchKeyword.toLowerCase());
  });
  
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleNavigateToHistory = () => {
    navigate('/data-and-history', { state: { from: '/assigned-devices' } });
  };

  const handleNavigateToGeofencing = () => {
    navigate('/geofencing', { state: { from: '/assigned-devices' } });
  };

  const handleNavigateToContact = () => {
    navigate('/contact-us', { state: { from: '/devices' } });
  };

  const handleUsersClick = () => {
    setShowUserList(true);
  };

  const handleCreateUserClick = async () => {
    setShowCreateUserForm(true);
  };


  const flyToDevice = (longitude, latitude) => {
    if (mapInstance.current) {
      if (longitude !== undefined && latitude !== undefined) {
        console.log(`Flying to location: ${latitude}, ${longitude}`);
        mapInstance.current.flyTo([latitude, longitude], 14, { duration: 3 });
      } else {
        console.error("Invalid location data for vehicle:", { longitude, latitude });
      }
    }
  };
  

  const handleLogicSelection = (deviceId, selectedLogic) => {
    if (!assignedLogics.includes(selectedLogic)) {
      console.warn(`Logic ${selectedLogic} is not assigned to this device.`);
      return;
    }

    let dashboardRoute = "";
    switch (selectedLogic) {
      case "logic1":
        navigate(`/logiconedashboard/${deviceId}`, { state: { from: '/assigned-devices' } });
        break;
      case "logic2":
        navigate(`/logiconedashboard/${deviceId}`, { state: { from: '/assigned-devices' } });
        break;
      case "logic3":
        navigate(`/logiconedashboard/${deviceId}`, { state: { from: '/assigned-devices' } });
        break;
      case "logic4":
        navigate(`/dashboard/${deviceId}`, { state: { from: '/assigned-devices' } });
        break;
      default:
        console.error("Unknown logic selected");
        return;
    }

    console.log(`Navigating to: ${dashboardRoute}`);
  };


  useEffect(() => {
    console.log("Device Details:", deviceDetails);
    console.log("Vehicle Data:", vehicleData);
  }, [deviceDetails, vehicleData]);
  
  
  return (
    <div className="userHome">
     <nav className="navbar">
        <div className="container --flex-between">
          <div className="navbar-brand">
            <img src={logo} alt="Company Logo" />
            <span>AR Experts LTD</span>
          </div>
          <h1 className="navbar-heading">Admin Dashboard</h1>
          <div className='dropdown'>
            <button id="dropdown-btn" className='dropdown-btn' onClick={toggleDropdown}><span className="icon"><FontAwesomeIcon icon={faBars} /></span><span className="text">Menu</span></button>
            <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
              <ul className="navbar-links">
                <li><button className="btn-primary-his" onClick={handleNavigateToHistory}><span className="icon"><FontAwesomeIcon icon={faHistory} /></span>Route History</button></li>
                <li><button className="btn-primary-geo" onClick={handleNavigateToGeofencing}><span className="icon"><FontAwesomeIcon icon={faMapMarkerAlt} /></span><span className="text">Geofencing</span></button></li>
                <li><button className="btn-primary-con" onClick={handleNavigateToContact}><span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span><span className="text">Contact Us</span></button></li>
                <li><button className="btn-primary-logout" onClick={logout}><span className="icon"><FontAwesomeIcon icon={faSignOutAlt} /></span><span className="text">Logout</span></button></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
  
      <main className="userContent">
              <div className="store-listt">

      <div className="heading">
            <h2>List of Devices</h2>
          </div>
        <div className='search-container'>
        <div className='search-box'>
          <input
            type="text"
            placeholder="Search devices by name..."
            value={searchKeyword}
            onChange={handleSearchChange}
            className="device-search-input"
          />
          <i className="fas fa-search search-icon"></i>
          </div>
          <button className="btn-primary-customm create" onClick={handleCreateUserClick}><i className="fas fa-user-plus"></i></button>
        </div>
            <button className="btn-primary-customm addDevv" onClick={() => setShowAddDeviceForm(true)}>Add Device</button>
            <button className="btn-primary-customm setupp" onClick={handleUsersClick}>Users</button>
            {showCreateUserForm && (
              <CreateClientUserForm
                userId={user._id}
                deviceDetails={vehicleData}
                onSubmit={handleCreateUserSubmit}
                onClose={() => setShowCreateUserForm(false)}
              />
            )}
            {showAddDeviceForm && (
              <AddDeviceForm
                onSubmit={handleAddDeviceSubmit}
                onClose={() => setShowAddDeviceForm(false)}
              />
            )}
            {showUserList && (
              <ClientUserList onClose={() => setShowUserList(false)} />
            )}
            <ul className="listt">
            {filteredDevices.map(([deviceId, data]) => {
              const telemetry = data.telemetry || {};
              const name = telemetry["device.name"] || `Device ${deviceId}`;
              const position = telemetry.position || {};
              const { latitude, longitude } = position;
              const imei = telemetry.ident;


    return (
      <li key={deviceId}>
        <div className="shop-items">
          <Link to="#" className="link-button" onClick={() => flyToDevice(longitude, latitude)}>
            {name} 
          </Link>
          <p>
            <strong>IMEI:</strong> {imei}
          </p>
          <p>
            <strong>Location:</strong>{" "}
            {latitude && longitude
              ? `${latitude}, ${longitude}`
              : "Fetching..."} 
          </p>
          <div className="logic-selections">
            <select
              className="selectt"
              onChange={(e) => handleLogicSelection(imei, e.target.value)}
            >
              <option value="">Select a logic</option>
              {["logic1", "logic2", "logic3", "logic4"].map((logic) => (
                <option
                  key={logic}
                  value={logic}
                  title={!assignedLogics.includes(logic) ? logicTooltips[logic] : ""}
                  disabled={!assignedLogics.includes(logic)}
                >
                  {logic} {assignedLogics.includes(logic) ? "(Assigned)" : ""}
                </option>
              ))}
            </select>
          </div>
        </div>
      </li>
    );
  })}
</ul>;

          </div>
        <div className="map-containerr">
          <div id="map" ref={mapRef}></div>
        </div>
      </main>
    </div>
  );
}  

export default AssignedDevices;