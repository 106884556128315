import React, { useState, useRef, useEffect } from 'react';
import mqtt from 'mqtt';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useNavigate } from "react-router-dom";
import "./Home.scss";
import { useDispatch } from 'react-redux';
import logo from "../../assets/AR logo.png";
import { BACKEND_URL, logoutUser } from "../../services/authService";
import { SET_LOGIN } from "../../redux/features/auth/authSlice";
import deliveryVanIconUrl from "../../assets/delivery_van_icon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHistory, faMapMarkerAlt, faEnvelope, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import 'leaflet.heat';
import DeviceList from '../../components/deviceList/DeviceList';
import AddDeviceForm from '../../components/deviceList/AddDevice';
import CreateGeofenceForm from '../../components/deviceList/CreateGeofenceForm';
import axios from 'axios';

// Integrated CreateUserForm directly within Home.js

const CreateUserForm = ({vehicleData, deviceDetails, onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    deviceIds: [],
    logics: []
  });
  const [selectAllDevices, setSelectAllDevices] = useState(false);
  const [selectAllLogics, setSelectAllLogics] = useState(false);

  const logicOptions = ["logic1", "logic2", "logic3", "logic4"]; // Define available logics

// Update select all devices logic
const handleSelectAllDevicesChange = () => {
  if (selectAllDevices) {
    // Deselect all devices
    setFormData((prevFormData) => ({ ...prevFormData, deviceIds: [] }));
  } else {
    // Select all devices in the format "deviceName/deviceId"
    const allDevices = Object.entries(vehicleData).map(([deviceId, data]) => {
      const deviceName = data["device.name"] || `Device ${deviceId}`;
      return `${deviceName}/${deviceId}`;
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      deviceIds: allDevices, // Update the deviceIds array
    }));
  }
  setSelectAllDevices(!selectAllDevices);
};

// Handle individual device selection
const handleDeviceSelection = (deviceId, deviceName) => {
  setFormData((prevFormData) => {
    const formattedDevice = `${deviceName}/${deviceId}`;
    const updatedDeviceIds = prevFormData.deviceIds.includes(formattedDevice)
      ? prevFormData.deviceIds.filter((name) => name !== formattedDevice) // Remove if exists
      : [...prevFormData.deviceIds, formattedDevice]; // Add if not exists

    // Update "Select All" checkbox based on the current selection
    setSelectAllDevices(updatedDeviceIds.length === Object.keys(vehicleData).length);

    return { ...prevFormData, deviceIds: updatedDeviceIds };
  });
};


  
  // Handle individual logic selection
  const handleLogicSelection = (logic) => {
    setFormData(prevFormData => {
      const updatedLogics = prevFormData.logics.includes(logic)
        ? prevFormData.logics.filter(l => l !== logic)
        : [...prevFormData.logics, logic];

      setSelectAllLogics(updatedLogics.length === logicOptions.length);
      return { ...prevFormData, logics: updatedLogics };
    });
  };

  // Handle select all logics
  const handleSelectAllLogicsChange = () => {
    if (selectAllLogics) {
      setFormData(prevFormData => ({ ...prevFormData, logics: [] }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        logics: logicOptions
      }));
    }
    setSelectAllLogics(!selectAllLogics);
  };

  // Handle other form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    console.log("Submitting Form Data:", formData);
    onSubmit(formData);
  };
  

  return (
    <div className="form-popup activee">
      <form onSubmit={handleSubmit}>
        <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
        <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
        <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
        <input type="password" name="confirmPassword" placeholder="Confirm Password" value={formData.confirmPassword} onChange={handleChange} required />
        <div className="checkbox-group">
  Devices:
  <label>
    <input
      type="checkbox"
      checked={selectAllDevices}
      onChange={handleSelectAllDevicesChange}
    />
    Select All
  </label>
  <div className="devicess-list">
    {Object.entries(vehicleData).map(([deviceId, data]) => {
      const deviceName = data["device.name"] || `Device ${deviceId}`;
      const formattedDevice = `${deviceName}/${deviceId}`;
      return (
        <label key={deviceId}>
          <input
            type="checkbox"
            value={formattedDevice}
            checked={formData.deviceIds.includes(formattedDevice)}
            onChange={() => handleDeviceSelection(deviceId, deviceName)}
          />
          {deviceName}
        </label>
      );
    })}
  </div>
</div>


        <div className="checkbox-group">
          Logics:
          <label>
            <input
              type="checkbox"
              checked={selectAllLogics}
              onChange={handleSelectAllLogicsChange}
            />
            Select All Logics
          </label>
          {logicOptions.map(logic => (
            <label key={logic}>
              <input
                type="checkbox"
                value={logic}
                checked={formData.logics.includes(logic)}
                onChange={() => handleLogicSelection(logic)}
              />
              {logic}
            </label>
          ))}
        </div>

        <button type="submit" className="btn-primary">Create User</button>
        <button type="button" className="close-btn" onClick={onClose}>Close</button>
      </form>
    </div>
  );
};

const Home = () => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markersRef = useRef([]);
  const heatLayerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [vehicleData, setVehicleData] = useState([]); 
  const [deviceDetails, setDeviceDetails] = useState({});
  const [selectedLogic, setSelectedLogic] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [currentTileLayer, setCurrentTileLayer] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCreateUserForm, setShowCreateUserForm] = useState(false);
const [showAddDeviceForm, setShowAddDeviceForm] = useState(false);
const [showUserList, setShowUserList] = useState(false);
const [createUserFormData, setCreateUserFormData] = useState({});
const [addDeviceFormData, setAddDeviceFormData] = useState({});
const closeCreateUserForm = () => setShowCreateUserForm(false);
const closeAddDeviceForm = () => setShowAddDeviceForm(false);
const dropdownRef = useRef(null); 
const [showCreateGeofenceForm, setShowCreateGeofenceForm] = useState(false);
const [dataLoaded, setDataLoaded] = useState(false);

  // Toggle dropdown menu
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    // If the click is outside the dropdown, close it
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const contextMenu = document.getElementById('contextMenu');
      if (contextMenu && !contextMenu.contains(event.target)) {
        contextMenu.style.display = 'none';
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const logout = async () => {
    await logoutUser();
    await dispatch(SET_LOGIN(false));
    navigate("/");
  };
  // Connect to MQTT broker and subscribe to topics
  useEffect(() => {
    const client = mqtt.connect('wss://mqtt.flespi.io:443', {
      username: process.env.REACT_APP_FLESPI_TOKEN,
      clientId: `web_${Math.random().toString(16).substr(2, 8)}`,
      clean: true,
    });

    client.on('connect', () => {
      setIsConnected(true);
      console.log('Connected to MQTT broker');
      const deviceIds = [
        5812973, 5850881, 5819862, 5850580, 5851101, 5870018, 5870272,
        5880168, 5880170, 5881262, 5882831, 5882911, 5886022, 5896298, 
        5896299, 5954456, 5964236, 5980883, 5983450, 5991154, 5992506,
        5992512, 5992514, 5992518, 5992522, 5992523, 5992527, 5992530,
        5992531, 5992533, 5992569, 6004115, 6004458, 6006149, 6006150, 6006173,
        6011942, 6012146, 6014117, 6016642, 6016644, 6018398, 6018402,
        6022452, 6022708, 6025021, 6025084, 6029459, 6029464, 6029476, 6029503,
        6029544, 6029553, 6029557, 6029576, 6037850, 6037863, 6037867,
        6038279, 6043264, 6043267, 6043268, 6043270, 6043271, 6043314,
        6043403, 6049583, 6056187, 6056192, 6056194, 6061832, 6061840, 
        6061844, 6061860, 6061864, 6061865, 6061867, 6061870, 6064683,
        6064685, 6064688, 6064690, 6064697, 6064700, 6075582, 6076224, 
        6077048, 6077055, 6077057, 6077090, 6077092, 6077093, 6077096, 
        6077098, 6077099, 6077100, 6077397, 6077400, 6077403, 5811400, 
        6077406, 6081884, 6081888, 6081895, 6084778, 6084781, 6084789,
        6088474, 6088476, 6088478, 6088479, 6090801, 6090803, 6092407,
        6077085, 6077095, 6087423, 6087430, 6087433, 6087448, 6087466,
        6087468, 6087470, 6087485, 6087489, 6087496, 6088474, 6092411, 
        6092412, 6092414, 6092421, 6092422, 6092423, 6092427, 6092430,  
        6092435, 6092452, 6092478, 6092493, 6094267, 6094275, 6094289,
        6094618, 6095277, 6095285, 6095288, 6095291, 6095292, 6095294,
        6095296, 6095298, 6095301, 6095303, 6095305, 6095306, 6095316,
        6095317, 6095318, 6092408, 6092409, 6097412, 6097414, 6097415,
        6097419, 6097422, 6097421, 6099000, 6099002, 6099003, 6099006,
        6101844, 6100587, 6105767, 6108963, 6108963, 6108965, 6108969,
        6108971, 6108972, 6061849, 6113203, 6121774, 6128121, 6129186,
        6129187
            ];

      deviceIds.forEach((deviceId) => {
        client.subscribe(`flespi/state/gw/devices/${deviceId}/telemetry/+`);
        client.subscribe(`flespi/state/gw/devices/${deviceId}/+`);
      });
    });

    client.on('message', (topic, message) => {
      const payload = JSON.parse(message.toString());
      const topicParts = topic.split('/');
      const deviceId = topicParts[4];
      const category = topicParts[5];
      const param = topicParts[6];
    
      setVehicleData((prevData) => {
        const updatedData = { ...prevData };
    
        // Ensure a structure exists for this deviceId
        if (!updatedData[deviceId]) {
          updatedData[deviceId] = { deviceId };
        }
    
        if (category === 'telemetry') {
          // Update telemetry data (e.g., position or other fields)
          updatedData[deviceId][param] = payload;
        } else if (category === 'configuration'){
          // Update metadata like name and IMEI
          updatedData[deviceId] = {
            ...updatedData[deviceId],
            'device.name': payload.name || updatedData[deviceId]['device.name'] || `Device ${deviceId}`,

            ident: payload.ident || updatedData[deviceId]?.ident || 'N/A',
          };
        }
    
        
                return updatedData;
      });
    });


    client.on('close', () => {
      setIsConnected(false);
      console.log('Disconnected from MQTT broker');
    });

    return () => {
      client.end();
    };
  }, []);

  // Initialize the map and add tile layers
  useEffect(() => {
    if (mapRef.current && !mapInstance.current) {
      const initializedMap = L.map(mapRef.current).setView([53.480759, -2.242631], 9);
      const defaultTileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://arexperts.co.uk/">AR Experts LTD</a>'
      });

      defaultTileLayer.addTo(initializedMap);
      setCurrentTileLayer(defaultTileLayer);

      const switchToLayer = (layerType) => {
        if (currentTileLayer) {
          initializedMap.removeLayer(currentTileLayer);
        }

        let newTileLayer;

        switch (layerType) {
          case 'roadmap':
            newTileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
              attribution: '&copy; <a href="http://arexperts.co.uk/">AR Experts LTD</a>'
            });
            break;
          case 'hybrid':
            newTileLayer = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
              attribution: '&copy; <a href="http://arexperts.co.uk/">AR Experts LTD</a>'
            });
            break;
          case 'heatmap':
            if (heatLayerRef.current) {
              initializedMap.removeLayer(heatLayerRef.current);
            }
            const heatData = vehicleData.map(device => {
              const { position } = device;
              return position && position.latitude && position.longitude
                ? [position.latitude, position.longitude, 5]
                : null;
            }).filter(Boolean);
            heatLayerRef.current = L.heatLayer(heatData, { radius: 25, blur: 15 }).addTo(initializedMap);
            break;
          default:
            newTileLayer = defaultTileLayer;
        }

        if (newTileLayer) {
          newTileLayer.addTo(initializedMap);
          setCurrentTileLayer(newTileLayer);
        }
      };

      const customControl = L.control({ position: 'bottomleft' });
      customControl.onAdd = function () {
        const div = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
        div.innerHTML = `
          <button class="map-button" id="roadmap">Roadmap</button>
          <button class="map-button" id="hybrid">Hybrid</button>
          <button class="map-button" id="heatmap">Heat Map</button>
        `;
        return div;
      };
      customControl.addTo(initializedMap);

      // Map button events
      L.DomEvent.on(L.DomUtil.get('roadmap'), 'click', () => switchToLayer('roadmap'));
      L.DomEvent.on(L.DomUtil.get('hybrid'), 'click', () => switchToLayer('hybrid'));
      L.DomEvent.on(L.DomUtil.get('heatmap'), 'click', () => switchToLayer('heatmap'));

      mapInstance.current = initializedMap;
      console.log('Map initialized');
    }
  }, [currentTileLayer, vehicleData]);

  // Update markers on the map based on real-time telemetry data
  useEffect(() => {
    if (mapInstance.current) {
      // Remove existing markers
      markersRef.current.forEach((marker) => mapInstance.current.removeLayer(marker));
      markersRef.current = [];
  
      // Iterate over vehicleData (treated as an object)
      Object.entries(vehicleData).forEach(([deviceId, data]) => {
        const { position } = data;
        if (position) {
          const { latitude, longitude } = position;
          const marker = L.marker([latitude, longitude], {
            icon: L.icon({
              iconUrl: deliveryVanIconUrl,
              iconSize: [30, 40],
              iconAnchor: [15, 40],
              popupAnchor: [0, -40],
            }),
          })
            .addTo(mapInstance.current)
            .bindPopup(`<strong>${deviceDetails[deviceId]?.name || `Device ${deviceId}`}</strong>`);
          markersRef.current.push(marker);
        }
      });
    }
  
  }, [vehicleData, deviceDetails]);
  
  vehicleData &&
    Object.entries(vehicleData).forEach(([deviceId, data]) => {
      const position = data.position || {};
      if (position.latitude && position.longitude) {
        const marker = L.marker([position.latitude, position.longitude], {
          icon: L.icon({
            iconUrl: deliveryVanIconUrl,
            iconSize: [30, 40],
            iconAnchor: [15, 40],
            popupAnchor: [0, -40],
          }),
        })
          .addTo(mapInstance.current)
          .bindPopup(`<strong>${vehicleData[deviceId]?.name || `Device ${deviceId}`}</strong>`)
        markersRef.current.push(marker);
      }
    });
  
  const flyToVehicle = (vehicle) => {
    if (mapInstance.current) {
      const { latitude, longitude } = vehicle.position;
      mapInstance.current.flyTo([latitude, longitude], 14, { duration: 3 });
    }
  };

  const handleLogicSelection = (event, vehicle) => {
    const logic = event.target.value;
    setSelectedLogic(logic);
  
    if (vehicle) {
      const { ident } = vehicle; // Use the IMEI or unique identifier of the vehicle
      let url = '';
  
      switch (logic) {
        case 'logic1':
          url = `/logiconedashboard/${ident}`;
          break;
        case 'logic2':
          url = `/logictwodashboard/${ident}`;
          break;
        case 'logic3':
          url = `/logicthreedashboard/${ident}`;
          break;
        case 'logic4':
        default:
          url = `/dashboard/${ident}`;
          break;
      }
  
      navigate(url);
    }
  };
  

  const handleCreateUserFormSubmit = async (formData) => {
    if (formData.password !== formData.confirmPassword) {
        alert("Passwords do not match!");
        return;
    }
    try {
      const response = await axios.post(`${BACKEND_URL}/api/users/registerWithDevice`, formData);
      if (response.status === 200 || response.status === 201) {
            alert("User created and confirmation email sent successfully!");
            setShowCreateUserForm(false);
        } else {
            alert(`Failed to create user: ${response.data.message}`);
        }
    } catch (error) {
        console.error("Error creating user:", error);
        alert(`Failed to create user: ${error.response ? error.response.data.message : error.message}`);
    }
};
  
  const handleCreateGeofenceSubmit = async (formData) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/api/geofence/create`, formData);
      if (response.status === 201 || response.status === 200) {
        alert("Geofence created successfully!");
        setShowCreateGeofenceForm(false);
      } else {
        alert(`Failed to create geofence: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Error creating geofence:", error);
      alert(`Failed to create geofence: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const handleNavigateToHistory = () => {
    navigate('/data-and-history', { state: { from: '/devices' } });
  };

  const handleNavigateToGeofencing = () => {
    navigate('/geofencing', { state: { from: '/devices' } });
  };

  const handleNavigateToContact = () => {
    navigate('/contact-us', { state: { from: '/devices' } });
  };
  return (
    <div className="home">
      <nav className="navbar">
        <div className="container --flex-between">
          <div className="navbar-brand">
            <img src={logo} alt="Company Logo" />
            <span>AR Experts LTD</span>
          </div>
          <h1 className="navbar-heading">Super Admin Dashboard</h1>
          <div className='dropdown' ref={dropdownRef}>
            <button id="dropdown-btn" className='dropdown-btn' onClick={toggleDropdown}><span className="icon"><FontAwesomeIcon icon={faBars} /></span><span className="text">Menu</span></button>
            <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
            <div className="navbar-links">
            <ul className="navbar-links">
                <li><button className="btn-primary-his" onClick={handleNavigateToHistory}><span className="icon"><FontAwesomeIcon icon={faHistory} /></span>Route History</button></li>
                <li><button className="btn-primary-geo" onClick={handleNavigateToGeofencing}><span className="icon"><FontAwesomeIcon icon={faMapMarkerAlt} /></span><span className="text">Geofencing</span></button></li>
                <li><button className="btn-primary-con" onClick={handleNavigateToContact}><span className="icon"><FontAwesomeIcon icon={faEnvelope} /></span><span className="text">Contact Us</span></button></li>
                <li><button className="btn-primary-logout" onClick={logout}><span className="icon"><FontAwesomeIcon icon={faSignOutAlt} /></span><span className="text">Logout</span></button></li>
              </ul>
          </div>
            </div>
          </div>  
        </div>
      </nav>
      <main className="main-content">
        
        <DeviceList 
          vehicleData={vehicleData} 
          deviceDetails={deviceDetails} 
          flyToVehicle={flyToVehicle} 
          showCreateUserForm={showCreateUserForm}
          showAddDeviceForm={showAddDeviceForm}
          showUserList={showUserList}
          createUserFormData={{}} 
          addDeviceFormData={{}} 
          toggleCreateUserForm={() => setShowCreateUserForm(!showCreateUserForm)}
          toggleAddDeviceForm={() => setShowAddDeviceForm(!showAddDeviceForm)}
          handleCreateUserInputChange={() => {}} 
          handleCreateUserCheckboxChange={() => {}} 
          handleCreateUserLogicChange={() => {}} 
          handleCreateUserFormSubmit={handleCreateUserFormSubmit}
          handleCreateGeofenceSubmit={handleCreateGeofenceSubmit}
          closeCreateUserForm={() => setShowCreateUserForm(false)}
          closeAddDeviceForm={() => setShowAddDeviceForm(false)}
          handleAddDeviceInputChange={() => {}} 
          handleAddDeviceFormSubmit={() => {}} 
          handleLogicSelection={handleLogicSelection}
          setSelectedVehicle={setSelectedVehicle}
          setShowUserList={setShowUserList} />
        {showCreateUserForm && <CreateUserForm   vehicleData={vehicleData}  onSubmit={handleCreateUserFormSubmit} onClose={() => setShowCreateUserForm(false)} />}
        {showAddDeviceForm && <AddDeviceForm onClose={() => setShowAddDeviceForm(false)} />}
        {showCreateGeofenceForm && (
        <CreateGeofenceForm
        vehicleData={vehicleData} 
          onSubmit={handleCreateGeofenceSubmit}
          onClose={() => setShowCreateGeofenceForm(false)}
        />
      )}

        <div ref={mapRef} className="map-container"></div>
      </main>
    </div>
  );
};

export default Home;