import mqtt from 'mqtt';
import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';

const useMqttService = (token, BACKEND_URL) => {
  const [vehicleData, setVehicleData] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const deviceDetailsRef = useRef({});

  // Fetch device details via API as a fallback
  const fetchDeviceDetails = useCallback(async (deviceId) => {
    if (deviceDetailsRef.current[deviceId]) return; // Return if details are already fetched

    const maxRetries = 5;
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    for (let i = 0; i < maxRetries; i++) {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/${deviceId}`, {
          headers: {
            'Authorization': `FlespiToken ${token}`,
            'Content-Type': 'application/json',
          },
        });
        
        if (response.data && response.data.result && response.data.result.length > 0) {
          const deviceData = response.data.result[0];
          deviceDetailsRef.current[deviceId] = {
            id: deviceData.id,
            name: deviceData.name || `Device ${deviceId}`,
            imei: deviceData.configuration?.ident,
          };
          setDeviceDetails({ ...deviceDetailsRef.current });
          return;
        } else {
          console.warn(`No device data found for device ID: ${deviceId}`);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 429) {
            const retryAfter = error.response.headers['retry-after']
              ? parseInt(error.response.headers['retry-after']) * 1000
              : (2 ** i) * 1000;
            console.warn(`Rate limit exceeded. Retrying after ${retryAfter} ms...`);
            await delay(retryAfter);
          } else {
            console.error('Error fetching device details:', error);
            break;
          }
        } else {
          console.error('Error fetching device details:', error);
          break;
        }
      }
    }
  }, [token, BACKEND_URL]);

  useEffect(() => {
    const client = mqtt.connect('wss://mqtt.flespi.io:443', {
      username: token,
      clientId: `web_${Math.random().toString(16).substr(2, 8)}`,
      clean: true,
    });

    client.on('connect', () => {
      setIsConnected(true);
      console.log('Connected to MQTT broker');

      const deviceIds = [
        5812973, 5850881, 5819862, 5850580, 5851101, 5870018, 5870272, 
      ];

      deviceIds.forEach((deviceId) => {
        client.subscribe(`flespi/state/gw/devices/${deviceId}/telemetry/+`);
        client.subscribe(`flespi/state/gw/devices/${deviceId}/+`);

        // Fetch additional details via API as a fallback
        fetchDeviceDetails(deviceId);
      });
    });

    client.on('message', (topic, message) => {
      const payload = JSON.parse(message.toString());
      const topicParts = topic.split('/');
      const deviceId = topicParts[4];
      const category = topicParts[5];
      const param = topicParts[6];

      if (category === 'telemetry') {
        setVehicleData((prevData) => ({
          ...prevData,
          [deviceId]: {
            ...prevData[deviceId],
            [param]: payload,
          },
        }));
      } else if (category === 'configuration' || category === 'name') {
        setDeviceDetails((prevDetails) => ({
          ...prevDetails,
          [deviceId]: {
            ...prevDetails[deviceId],
            id: payload.id,
            name: payload.name || `Device ${deviceId}`,
            imei: payload.configuration?.ident,
          },
        }));
      }
    });

    client.on('close', () => {
      setIsConnected(false);
      console.log('Disconnected from MQTT broker');
    });

    return () => {
      client.end();
    };
  }, [token, fetchDeviceDetails]);

  return { vehicleData, deviceDetails, isConnected };
};

export default useMqttService;
