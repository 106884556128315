import React from 'react';
import useMqttService from '../pages/MqttService'; 
import { BACKEND_URL } from '../services/authService';

const MqttDataDisplay = ({ token }) => {
  const { vehicleData, deviceDetails, isConnected } = useMqttService(token, BACKEND_URL);

  return (
    <div>
      <h1>MQTT Connection Status: {isConnected ? "Connected" : "Disconnected"}</h1>
      <h2>Device Details:</h2>
      <pre>{JSON.stringify(deviceDetails, null, 2)}</pre>
      <h2>Vehicle Telemetry Data:</h2>
      <div>
        {Object.entries(vehicleData).map(([deviceId, data]) => (
          <div key={deviceId}>
            <h3>Device {deviceId}</h3>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MqttDataDisplay;
