import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const CreateClientUserForm = ({ userId, onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    deviceIds: [],
    logics: []
  });
  const [assignedDevices, setAssignedDevices] = useState([]);
  const [assignedLogics, setAssignedLogics] = useState([]);
  const [selectAllDevices, setSelectAllDevices] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch devices
        const deviceResponse = await axios.get(`${BACKEND_URL}/api/users/${userId}/devices`);
        setAssignedDevices(deviceResponse.data);

        // Fetch logics
        const logicResponse = await axios.get(`${BACKEND_URL}/api/users/${userId}/logics`);
        setAssignedLogics(logicResponse.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleDeviceSelection = (deviceId) => {
    setFormData((prevFormData) => {
      const updatedDeviceIds = prevFormData.deviceIds.includes(deviceId)
        ? prevFormData.deviceIds.filter((id) => id !== deviceId)
        : [...prevFormData.deviceIds, deviceId];

      setSelectAllDevices(updatedDeviceIds.length === assignedDevices.length);
      return { ...prevFormData, deviceIds: updatedDeviceIds };
    });
  };

  const handleSelectAllDevicesChange = () => {
    if (selectAllDevices) {
      setFormData((prevFormData) => ({ ...prevFormData, deviceIds: [] }));
    } else {
      const allDeviceIds = assignedDevices.map((device) => device.id);
      setFormData((prevFormData) => ({ ...prevFormData, deviceIds: allDeviceIds }));
    }
    setSelectAllDevices(!selectAllDevices);
  };

  const handleLogicSelection = (logic) => {
    setFormData((prevFormData) => {
      const updatedLogics = prevFormData.logics.includes(logic)
        ? prevFormData.logics.filter((l) => l !== logic)
        : [...prevFormData.logics, logic];
      return { ...prevFormData, logics: updatedLogics };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    if (formData.deviceIds.length === 0) {
      alert("Please select at least one device.");
      return;
    }
    if (formData.logics.length === 0) {
      alert("Please select at least one logic.");
      return;
    }
    onSubmit(formData);
  };

  return (
    <div className="form-popup activee">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />

        <div className="checkbox-group">
          <strong>Devices:</strong>
          <label>
            <input
              type="checkbox"
              checked={selectAllDevices}
              onChange={handleSelectAllDevicesChange}
            />
            Select All
          </label>
          <div className='devicess-list'>
          {assignedDevices.map((device) => (
            <label key={device.id}>
              <input
                type="checkbox"
                value={device.id}
                checked={formData.deviceIds.includes(device.id)}
                onChange={() => handleDeviceSelection(device.id)}
              />
              {device.name || `Device ${device.id}`}
            </label>
          ))}
          </div>
        </div>

        <div className="checkbox-group">
          <strong>Logics:</strong>
          {assignedLogics.map((logic) => (
            <label key={logic}>
              <input
                type="checkbox"
                value={logic}
                checked={formData.logics.includes(logic)}
                onChange={() => handleLogicSelection(logic)}
              />
              {logic}
            </label>
          ))}
        </div>

        <button type="submit" className="btn-primary">Create User</button>
        <button type="button" className="close-btn" onClick={onClose}>Close</button>
      </form>
    </div>
  );
};

CreateClientUserForm.propTypes = {
  userId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateClientUserForm;
