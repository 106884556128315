import { fetchStart, fetchSuccess, fetchFailure, setTelemetryDataForDevice } from './vehicleTelemetryActions';
import productService from '../../features/product/productService';

const { getTelemetryData, getTelemetryDataForDevice } = productService;

export const getVehicleTelemetryData = () => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const data = await getTelemetryData();
    dispatch(fetchSuccess(data));
  } catch (error) {
    dispatch(fetchFailure(error.message));
  }
};

export const getVehicleTelemetryDataForDevice = (deviceId) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const data = await getTelemetryDataForDevice(deviceId);
    dispatch(setTelemetryDataForDevice(deviceId, data));
    dispatch(fetchSuccess(data)); // Optional: If you want to track the overall success state
  } catch (error) {
    dispatch(fetchFailure(error.message));
  }
};
