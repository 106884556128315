import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserList from '../../components/users/UserList';
import CreateGeofenceForm from '../../components/deviceList/CreateGeofenceForm';
import productService from "../../redux/features/product/productService";

const { fetchAddress } = productService;
const DeviceList = ({
  vehicleData,
  deviceDetails,
  showCreateUserForm,
  showAddDeviceForm,
  showUserList,
  toggleCreateUserForm,
  toggleAddDeviceForm,
  flyToVehicle,
  handleLogicSelection,
  setShowUserList,
  selectedLogic,
}) => {
  const [showCreateGeofenceForm, setShowCreateGeofenceForm] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(false);

  // Filter devices based on search query
  const [searchKeyword, setSearchKeyword] = useState('');

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  // Filter devices based on search keyword
  const filteredDevices = Object.entries(vehicleData).filter(([deviceId, data]) => {
    const deviceName = data['device.name'] || '';
    return deviceName.toLowerCase().includes(searchKeyword.toLowerCase());
  });

  return (
    <div className="store-list">
      <div className="heading">
        <h2>List of Devices</h2>
      </div>
      <div className="search-container-home">
        <div className='search-box'>
        <input
          type="text"
          placeholder="Search devices by name..."
          value={searchKeyword}
          onChange={handleSearchChange}
          className="search-input"
        />
                <i className="fas fa-search search-icon"></i>
        </div>
        <button className="btn-primary-custom create" onClick={toggleCreateUserForm}>
          <i className="fas fa-user-plus"></i>
        </button>
      </div>
      <div className="button-container-home">
        <button className="btn-primary-custom" onClick={toggleAddDeviceForm}>
          Add Device
        </button>
        <button className="btn-primary-custom" onClick={() => setShowUserList(!showUserList)}>
          Users
        </button>
        <button className="btn-primary-custom" onClick={() => setShowCreateGeofenceForm(true)}>
          Create Geofence
        </button>
        
      </div>
      {showUserList && <UserList />}
      {showCreateGeofenceForm && (
        <CreateGeofenceForm
          deviceDetails={deviceDetails}
          onSubmit={(data) => {
            console.log('Geofence data:', data);
            setShowCreateGeofenceForm(false);
          }}
          onClose={() => setShowCreateGeofenceForm(false)}
        />
      )}
<ul className="list">
  {filteredDevices.map(([deviceId, data]) => {
    const name = data['device.name'];
    const imei = data?.ident || 'N/A';

    return (
      <li key={deviceId} className="device-item">
        <div className="shop-item">
          <button
            className="link-button"
            onClick={() => {
              flyToVehicle(data);
              setSelectedVehicle(data);
            }}
          >
            {name}
          </button>
          <div className="vehicle-info">
            <p>
              <strong>IMEI:</strong> {imei}
            </p>
            <p>
              <strong>Location:</strong>{' '}
              {data.position?.latitude && data.position?.longitude
                ? `${data.position.latitude}, ${data.position.longitude}`
                : 'Location data not available'}
            </p>
          </div>
          {selectedVehicle === data && (
            <div className="logic-selection">
              <select
                onChange={(event) => {
                  handleLogicSelection(event, data);
                }}
                value={selectedLogic || ''}
              >
                <option value="" disabled>
                  Select Logic
                </option>
                <option value="logic1">Logic 1</option>
                <option value="logic2">Logic 2</option>
                <option value="logic3">Logic 3</option>
                <option value="logic4">Logic 4</option>
              </select>
            </div>
          )}
        </div>
      </li>
    );
  })}
</ul>

    </div>
  );
};

DeviceList.propTypes = {
  vehicleData: PropTypes.array.isRequired,
  deviceDetails: PropTypes.object.isRequired,
  flyToVehicle: PropTypes.func.isRequired,
  showCreateUserForm: PropTypes.bool,
  showAddDeviceForm: PropTypes.bool,
  showUserList: PropTypes.bool,
  toggleCreateUserForm: PropTypes.func,
  toggleAddDeviceForm: PropTypes.func,
  setShowUserList: PropTypes.func,
  handleLogicSelection: PropTypes.func,
  setSelectedVehicle: PropTypes.func,
  selectedVehicle: PropTypes.object,
};

export default DeviceList;



// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import UserList from '../../components/users/UserList';
// import CreateGeofenceForm from '../../components/deviceList/CreateGeofenceForm';
// import productService from "../../redux/features/product/productService";

// const { fetchAddress } = productService;

// const DeviceList = ({
//   vehicleData,
//   deviceDetails,
//   showCreateUserForm,
//   showAddDeviceForm,
//   showUserList,
//   toggleCreateUserForm,
//   toggleAddDeviceForm,
//   flyToVehicle,
//   setShowUserList,
// }) => {
//   const [showCreateGeofenceForm, setShowCreateGeofenceForm] = useState(false);
//   const [searchKeyword, setSearchKeyword] = useState('');
//   const [addresses, setAddresses] = useState({}); // State to store fetched addresses

//   // Fetch address for each device with valid coordinates
//   useEffect(() => {
//     const fetchAllAddresses = async () => {
//       const updatedAddresses = { ...addresses };
  
//       const fetchWithDelay = async (deviceId, latitude, longitude, delay) => {
//         await new Promise((resolve) => setTimeout(resolve, delay)); // Add delay
//         const address = await fetchAddress(latitude, longitude);
//         updatedAddresses[deviceId] = address;
//         setAddresses({ ...updatedAddresses });
//       };
  
//       let delay = 0;
//       for (const [deviceId, data] of Object.entries(vehicleData)) {
//         if (data.position?.latitude && data.position?.longitude) {
//           const { latitude, longitude } = data.position;
//           if (!updatedAddresses[deviceId]) {
//             updatedAddresses[deviceId] = 'Fetching...';
//             fetchWithDelay(deviceId, latitude, longitude, delay);
//             delay += 1000; // 1-second delay between requests
//           }
//         }
//       }
//     };
  
//     fetchAllAddresses();
//   }, [vehicleData]);
  

//   // Filter devices based on search keyword
//   const filteredDevices = Object.entries(vehicleData).filter(([deviceId, data]) => {
//     const deviceName = data['device.name'] || '';
//     return deviceName.toLowerCase().includes(searchKeyword.toLowerCase());
//   });

//   return (
//     <div className="store-list">
//       <div className="heading">
//         <h2>List of Devices</h2>
//       </div>
//       <div className="search-container-home">
//         <input
//           type="text"
//           placeholder="Search devices by name..."
//           value={searchKeyword}
//           onChange={(e) => setSearchKeyword(e.target.value)}
//           className="search-input"
//         />
//         <i className="fas fa-search search-icon"></i>
//         <button className="btn-primary-custom create" onClick={toggleCreateUserForm}>
//           <i className="fas fa-user-plus"></i>
//         </button>
//       </div>
//       <div className="button-container-home">
//         <button className="btn-primary-custom" onClick={toggleAddDeviceForm}>
//           Add Device
//         </button>
//         <button className="btn-primary-custom" onClick={() => setShowUserList(!showUserList)}>
//           Users
//         </button>
//         <button className="btn-primary-custom" onClick={() => setShowCreateGeofenceForm(true)}>
//           Create Geofence
//         </button>
//       </div>
//       {showUserList && <UserList />}
//       {showCreateGeofenceForm && (
//         <CreateGeofenceForm
//           deviceDetails={deviceDetails}
//           onSubmit={(data) => {
//             console.log('Geofence data:', data);
//             setShowCreateGeofenceForm(false);
//           }}
//           onClose={() => setShowCreateGeofenceForm(false)}
//         />
//       )}
//       <ul className="list">
//         {filteredDevices.map(([deviceId, data]) => {
//           const name = data['device.name'];
//           const imei = data?.ident || 'N/A';
//           const location = addresses[deviceId] || 'Fetching address...';

//           return (
//             <li key={deviceId} className="device-item" onClick={() => flyToVehicle(data)}>
//               <div className="shop-item">
//                 <button className="link-button">{name}</button>
//                 <div className="vehicle-info">
//                   <p>
//                     <strong>IMEI:</strong> {imei}
//                   </p>
//                   <p>
//                     <strong>Location:</strong> {location}
//                   </p>
//                 </div>
//               </div>
//             </li>
//           );
//         })}
//       </ul>
//     </div>
//   );
// };

// DeviceList.propTypes = {
//   vehicleData: PropTypes.object.isRequired,
//   deviceDetails: PropTypes.object.isRequired,
//   flyToVehicle: PropTypes.func.isRequired,
//   showCreateUserForm: PropTypes.bool,
//   showAddDeviceForm: PropTypes.bool,
//   showUserList: PropTypes.bool,
//   toggleCreateUserForm: PropTypes.func,
//   toggleAddDeviceForm: PropTypes.func,
//   setShowUserList: PropTypes.func,
// };

// export default DeviceList;