import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import productService from '../../redux/features/product/productService';
import '../LogicTwoDashboard/LogictwoDashboard.scss';
import VehicleActivityLogs from '../../components/vehicleActivityLogs/VehicleActivityLogs';
import GeofenceLogs from '../../components/geofenceLogs/GeofenceLogs';
import DeviceControl from '../../components/deviceControl/Logic1/DeviceControl';
import FeedbackMessage from '../../components/feedbackMessage/FeedbackMessage';
import { selectTelemetryDataForDevice } from '../../redux/selectors';
import { getVehicleTelemetryDataForDevice } from '../../redux/features/product/vehicleTelemetryThunks';
import VehicleEfficiency from '../../components/vehicleEfficiency/VehicleEfficiency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import deliveryVanIconUrl from "../../assets/delivery_van_icon.png";

const { fetchAddress, sendCommandToFlespi } = productService;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REACT_APP_FLESPI_TOKEN = process.env.REACT_APP_FLESPI_TOKEN;

const initialState = {
  currentSpeed: 0,
  vehicleIdling: false,
  vehicleParameters: [],
  address: '',
  isVehicleParked: true,
  isVehicleBlocked: false,
  acceleration: 0,
  scores: {
    accelerationScore: 100,
    brakingScore: 100,
    corneringScore: 100,
    speedingScore: 100,
  },
  doorStatus: {
    frontLeft: false,
    frontRight: false,
    rearLeft: false,
    rearRight: false,
    trunk: false,
  }
};

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_TELEMETRY':
      return { ...state, ...action.payload };
    case 'UPDATE_ADDRESS':
    case 'UPDATE_PARKED_STATUS':
    case 'TOGGLE_VEHICLE_BLOCK':
    case 'UPDATE_SCORES':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const LogicTwoDashboard = () => {
  const { imei } = useParams();
  const { deviceId } = useParams(); 
  const [commandStatus, setCommandStatus] = useState({
    condition1Sent: false,
    condition2Sent: false,
    condition3Sent: false,
  });
  const [doorOpenTime, setDoorOpenTime] = useState(null);
  const [doorClosedAfterOpening, setDoorClosedAfterOpening] = useState(false);
  const [doorClosedWithinTime, setDoorClosedWithinTime] = useState(false);
  const [doorStatus, setDoorStatus] = useState({
    frontLeft: false,
    frontRight: false,
    rearLeft: false,
    rearRight: false,
    trunk: false,
  });
  const [commandSent, setCommandSent] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [devicename, setDevicename] = useState('');
  const dispatch = useDispatch();
  const [state, localDispatch] = useReducer(reducer, initialState);
  const [feedback, setFeedback] = useState({ message: '', type: '' });
  const [logs, setLogs] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markerRef = useRef(null);
  const [telemetryData, setTelemetryData] = useState([]);

  const handleHomeClick = () => {
    const previousPage = location.state?.from || '/devices';
    navigate(previousPage);
  };

  const fetchTelemetryData = useCallback(async (deviceId) => {
    const maxRetries = 5;

    for (let i = 0; i < maxRetries; i++) {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/${deviceId}/telemetry/position,can.vehicle.mileage,can.engine.rpm,can.fuel.level,can.engine.ignition.status,can.handbrake.status,can.vehicle.speed,can.front.left.door.status`, {
          headers: {
            'Authorization': `FlespiToken ${REACT_APP_FLESPI_TOKEN}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.data && response.data.result && response.data.result.length > 0) {
          const telemetry = response.data.result[0].telemetry;
          setTelemetryData(telemetry);
          console.log('Fetched telemetry data:', telemetry);
          return;
        } else {
          console.warn(`No telemetry data found for device ID: ${deviceId}`);
        }
      } catch (error) {
        if (error.response && error.response.status === 429) {
          const retryAfter = error.response.headers['retry-after']
            ? parseInt(error.response.headers['retry-after']) * 1000
            : (2 ** i) * 1000;
          console.warn(`Rate limit exceeded. Retrying after ${retryAfter} ms...`);
          await new Promise(resolve => setTimeout(resolve, retryAfter));
        } else {
          console.error('Error fetching telemetry data:', error);
          break;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (deviceDetails && deviceDetails.id) {
      console.log(`IMEI in VehicleActivityLogs: ${imei}`);
      fetchTelemetryData(deviceDetails.id);
    }
  }, [imei, deviceDetails, fetchTelemetryData]);
  // Fetch all devices and map IMEI to device ID
  useEffect(() => {
    const fetchAllDevices = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/flespi/gw/devices/telemetry.channel.id=1211469`, {
          headers: {
            'Authorization': `FlespiToken ${process.env.REACT_APP_FLESPI_TOKEN}`,
            'Content-Type': 'application/json',
          },
        });

        console.log('Fetched devices:', response.data);

        if (response.data && response.data.result && response.data.result.length > 0) {
          const device = response.data.result.find(d => d.configuration.ident === deviceId);
          if (device) {
            setDeviceDetails(device);
            console.log('Device details:', device);
            if (device.name) {
              console.log('Device Name:', device.name);
              setDevicename(device.name); // Set the device name in state
            } else {
              console.warn('Device name not found');
            }
          } else {
            console.warn('Device ID not found for IMEI:', deviceId);
          }
        } else {
          console.warn('No devices found');
        }
      } catch (error) {
        console.error('Error fetching devices:', error.response?.data || error.message);
      }
    };

    fetchAllDevices();
  }, [deviceId]);

  // Fetch telemetry data for the specific device using IMEI
  useEffect(() => {
    dispatch(getVehicleTelemetryDataForDevice(deviceId));
  }, [dispatch, deviceId]);

  // Update telemetry data
  useEffect(() => {
    if (telemetryData.length > 0) {
      const latestData = telemetryData[telemetryData.length - 1];

      const newDoorStatus = {
        frontLeft: latestData['can.front.left.door.status'],
        // Update other door statuses as needed
      };

      setDoorStatus((prevState) => ({
        ...prevState,
        ...newDoorStatus,
      }));

      localDispatch({
        type: 'UPDATE_TELEMETRY',
        payload: {
          doorStatus: newDoorStatus,
        },
      });
    }
  }, [telemetryData, setDoorStatus, localDispatch]);

  // Send command using the device ID obtained from the device details
  const sendCommand = useCallback(
    async (command) => {
      console.log(`Button clicked. Sending command: ${command}`);
      if (!deviceDetails) {
        console.error('Device details not available to send command');
        return;
      }

      console.log(`Sending command to device ID: ${deviceDetails.id}`);
      
      try {
        await sendCommandToFlespi(deviceDetails.id, command, {
          properties: {},
          address: 'connection',
        });
        setFeedback({ message: `Command to ${command} sent successfully`, type: 'success' });
        console.log(`Command ${command} sent successfully to device ID: ${deviceDetails.id}`);
      } catch (error) {
        console.error(`Failed to send command to ${command}:`, error);
        setFeedback({ message: `Failed to send command to ${command}`, type: 'error' });
      } finally {
        setCommandSent(false);
      }
    },
    [deviceDetails]
  );

  const doorTimerRef = useRef(null);

  useEffect(() => {
    if (!telemetryData.length) return;

    const latestData = telemetryData[telemetryData.length - 1];
    const engineStatus = latestData['engine.ignition.status'];
    const currentSpeed = latestData['can.vehicle.speed'];
    const doorOpened = latestData['can.front.left.door.status'];
    const doorLocked = latestData['can.car.closed.status'];

    console.log('Telemetry data:', { engineStatus, currentSpeed, doorOpened, doorLocked });

    if (doorStatus.frontLeft !== doorOpened) {
      setDoorStatus((prevStatus) => ({
        ...prevStatus,
        frontLeft: doorOpened,
      }));
    }

    if (!engineStatus && doorOpened) {
      setDoorOpenTime(Date.now());
    }

    if (engineStatus) {
      if (doorOpened) {
        setDoorOpenTime(Date.now());
      } else if (!doorOpened && doorOpenTime) {
        setDoorClosedAfterOpening(true);
        setDoorOpenTime(null);
      }
    } else {
      setDoorClosedAfterOpening(false);
      setDoorOpenTime(null);
    }

    if (doorOpened) {
      setDoorOpenTime(Date.now());
      setDoorClosedWithinTime(false);
    } else {
      const timeDiff = (Date.now() - doorOpenTime) / 1000;
      if (timeDiff <= 7 && doorOpenTime) {
        setDoorClosedWithinTime(true);
      }
    }

    const attemptToSendCommands = async () => {
      console.log('Attempting to send commands:', { engineStatus, currentSpeed, doorClosedAfterOpening, doorStatus, doorClosedWithinTime, doorLocked });

      if (engineStatus && currentSpeed === 0 && doorClosedAfterOpening) {
        if (!commandStatus.condition1Sent) {
          await sendCommand('lvcanclosealldoors');
          setCommandStatus((prev) => ({ ...prev, condition1Sent: true }));
        }
      } else {
        setCommandStatus((prev) => ({ ...prev, condition1Sent: false }));
      }

      if (engineStatus && currentSpeed === 0 && Object.values(doorStatus).some((status) => status)) {
        if (!commandStatus.condition2Sent) {
          await sendCommand('lvcanclosealldoors');
          setCommandStatus((prev) => ({ ...prev, condition2Sent: true }));
        }
      } else {
        setCommandStatus((prev) => ({ ...prev, condition2Sent: false }));
      }

      if (!engineStatus && currentSpeed === 0 && doorClosedWithinTime && !doorLocked) {
        if (!commandStatus.condition3Sent) {
          await sendCommand('lvcanclosealldoors');
          setCommandStatus((prev) => ({ ...prev, condition3Sent: true }));
        }
      } else {
        setCommandStatus((prev) => ({ ...prev, condition3Sent: false }));
      }
    };

    if (doorTimerRef.current) clearTimeout(doorTimerRef.current);
    doorTimerRef.current = setTimeout(attemptToSendCommands, 5000);

    return () => {
      if (doorTimerRef.current) clearTimeout(doorTimerRef.current);
    };
  }, [
    telemetryData,
    doorClosedAfterOpening,
    doorClosedWithinTime,
    doorStatus,
    doorOpenTime,
    commandSent,
    sendCommand,
    commandStatus,
  ]);

  const closeFeedback = useCallback(() => {
    setFeedback({ message: '', type: '' });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getVehicleTelemetryDataForDevice(deviceId));
    }, 10000); // Set interval to 10 seconds
    return () => clearInterval(intervalId);
  }, [dispatch, deviceId]);

  useEffect(() => {
    if (mapRef.current && !mapInstance.current) {
      mapInstance.current = L.map(mapRef.current).setView([53.4808, -2.2426], 6);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors',
      }).addTo(mapInstance.current);
    }

    if (mapInstance.current && telemetryData?.position?.value) {
      const { latitude, longitude } = telemetryData.position.value;

      if (latitude && longitude) {
        if (markerRef.current) {
          mapInstance.current.removeLayer(markerRef.current);
        }

        markerRef.current = L.marker([latitude, longitude], {
          icon: L.icon({
            iconUrl: deliveryVanIconUrl,
            iconSize: [30, 40],
            iconAnchor: [15, 40],
            popupAnchor: [0, -40],
          })
        }).addTo(mapInstance.current);

        mapInstance.current.setView([latitude, longitude], 14);
      }
    }
  }, [telemetryData]);

  return (
    <div className="dashboard2">
     <nav className='navbar'>
        <div className='container --flex-between'>
        <h1 className='navbar-heading'>Logic-2 dashboard of {devicename}</h1>
      <div className="navbar-links">
        <ul>
          <li>
            <button className="btn-primary-home" onClick={handleHomeClick}>
              <span className="icon"><FontAwesomeIcon icon={faHome} /></span>
              <span className="text">Go back to Homepage</span>
            </button>
          </li>
        </ul>
      </div>
      </div>
      </nav>
      <br></br>
      <div className="grid-container">
        <div className='right'>
          <VehicleActivityLogs           
            telemetryData={telemetryData} 
            fetchAddress={fetchAddress} 
            doorStatus={state.doorStatus} 
            deviceDetails={deviceDetails} 
            deviceId={deviceId} 
          />
        </div>
        <div className='center-column'>
 <div className='control'>
            <DeviceControl sendCommand={sendCommand} />
            <FeedbackMessage feedback={feedback} closeFeedback={closeFeedback} />

          </div>

          <div id="mapp" ref={mapRef} className="map-containerr"></div>
        </div>

        <div className='left'>
        {deviceDetails && (
          <VehicleEfficiency
            deviceId={deviceDetails.id}
            telemetryData={telemetryData}
            imei={imei}
          />
        )}
        </div>
      </div>
    </div>
  );
};

export default LogicTwoDashboard;