import React, { useState } from 'react';
import PropTypes from 'prop-types';
import emailjs from 'emailjs-com';

const AddDeviceForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    CompanyName: "",
    vehicleNumberPlate: "",
    imeiNumber: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const templateParams = {
      to_email: "telematics@arexperts.co.uk",
      CompanyName: formData.CompanyName,
      vehicleNumberPlate: formData.vehicleNumberPlate,
      imeiNumber: formData.imeiNumber
    };

    try {
      await emailjs.send('service_uac4877', 'template_bun4byx', templateParams, 'AFY397KRKcaNc1_-s');
      alert("Device addition request sent successfully!");
      onClose();
      setFormData({
        CompanyName: "",
        vehicleNumberPlate: "",
        imeiNumber: ""
      });
    } catch (error) {
      console.error('Failed to send device addition request:', error);
      alert("Failed to send device addition request.");
    }
  };

  return (
    <div className="form-popup activee">
      <form onSubmit={handleSubmit}>
        <input type="text" name="CompanyName" placeholder="Company Name" value={formData.CompanyName} onChange={handleChange} required />
        <input type="text" name="vehicleNumberPlate" placeholder="Vehicle Number Plate" value={formData.vehicleNumberPlate} onChange={handleChange} required />
        <input type="text" name="imeiNumber" placeholder="IMEI Number" value={formData.imeiNumber} onChange={handleChange} required />
        <button type="submit" className="btn-primary">Add Device</button>
        <button type="button" className="close-btn" onClick={onClose}>Close</button>
      </form>
    </div>
  );
};

AddDeviceForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddDeviceForm;
