import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "../../assets/AR logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const History = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleHomeClick = () => {
    const previousPage = location.state?.from || '/home';
    navigate(previousPage);
  };
  return (
    <div>
      <nav className="navbar">
        <div className="container --flex-between">
          <div className="navbar-brand">
            <img src={logo} alt="Company Logo" />
            <span>AR Experts LTD</span>
          </div>
          <h1 className="navbar-heading">Route History</h1>
          <div className="navbar-links">
          <ul>
            <li>
              <button className="btn-primary-home" onClick={handleHomeClick}>
                <span className="icon"><FontAwesomeIcon icon={faHome} /></span>
                <span className="text">Home</span>
              </button>
            </li>
          </ul>
        </div>
        </div>
      </nav>
        <br></br>
        <br></br>
        <div className='frame'>
        <iframe src="https://66a17b0c57ee2e52c7bd79fe--rainbow-froyo-55edde.netlify.app" style={{ width: '100%', height: '600px', border: 'none' }} title="History Page"></iframe>
        </div>
    </div>
  );
};

export default History;
